import React from 'react';
import HeroTitle from '../components/HeroTitle.js';
import Layout from '../components/layout.js';
import { CommonContext } from '../context.js';
import { PropTypes } from 'prop-types';

const PrivacyPolicy = (props) => (
  <CommonContext.Provider value={{ location: props.location }}>
    <Layout title="Privacy Policy" location={props.location}>
      <section>
        <div className="flex justify-center">
          <div className="w-full max-w-6xl px-6 md:px-0">
            <HeroTitle
              text="Privacy Policy"
              className="m-auto mt-12 mb-8 md:text-center"
            />
            <div className="flex justify-center mt-0 mb-24 md:mt-8">
              <div className="w-full max-w-xl p-6 blog markdown md:p-0">
                <p>
                  Greentech Apps Foundation is immensely grateful for allowing
                  us to communicate with you. The reason for this notice is so
                  that Greentech Apps Foundation can comply with the law on data
                  protection and in so doing assure you that the personal
                  information that you have provided to us is dealt with
                  properly and securely and so that you know how to seek a
                  remedy if you are not satisfied with our use of your
                  information. Greentech Apps Foundation is a Data Controller
                  and is a joint data controller in respect of the information
                  that you have provided to us in order to enable us to process
                  your donation. This means that Greentech Apps Foundation is
                  responsible for the proper use and safe custody of your
                  personal information.
                </p>
                <h3>Data Controller</h3>

                <p>
                  If you have any concerns or complaints with regard to the
                  processing of the personal information that you have provided
                  to us you can contact Data Protection, at the following
                  address: 20 Lilac Court, Cambridge, CB1 7AY, United Kingdom or
                  by e-mail to:{' '}
                  <a href="mailto:dataprotection@gtaf.org">
                    {' '}
                    dataprotection@gtaf.org{' '}
                  </a>{' '}
                  Greentech Apps Foundation does not have a statutory Data
                  Protection Officer and it is not required to have one.
                  <p className="section-heading">
                    Purposes of Processing and Retention Period
                  </p>
                  <p>
                    Greentech Apps Foundation has obtained from you some
                    personal information today. This information is sufficient
                    to identify you and to enable to communicate with you by the
                    means that you have elected (such as email) and for the
                    specified purposes (such as keeping up to date with
                    Greentech Apps Foundation’s work). Since you have elected to
                    receive further information from Greentech Apps Foundation,
                    such as our newsletter, then we have to retain contact
                    information, including your identity and contact details,
                    for as long as you wish to continue to receive information
                    from us. We will contact you once every year in order to
                    verify that you wish us to continue to contact you in this
                    way.
                    <br />
                    We therefore hold your information for these purposes with
                    your consent. You may withdraw your consent at any time by
                    emailing{' '}
                    <a href="mailto:support@gtaf.org">support@gtaf.org</a>
                  </p>
                  <p className="section-heading">
                    {' '}
                    Recipients of Your Personal Data
                  </p>
                  <p>
                    Greentech Apps Foundation does not sell, give, transfer or
                    otherwise use your personal data other than for the purposes
                    mentioned above.
                    <br />
                    In order to enable us to receive personal data for the
                    specified purposes, we may provide your identity to a number
                    of services that automate your data, we have carefully
                    selected these services in a manner that is compliant with
                    the regulation (see Automated Decision Making).
                    <br />
                    Greentech Apps Foundation does not send your personal
                    information outside of the European Union. If any of the
                    services does this then they are obliged to explain this to
                    you.
                  </p>
                  <h3> Your Rights </h3>
                  <p>
                    We are also obliged to explain to you your rights in respect
                    of our processing of your personal data. These are:
                    <ul>
                      <li>
                        {' '}
                        The right to request from Greentech Apps Foundation a
                        copy of your personal information as it is held by us.
                        There is normally no charge for us doing this.
                      </li>
                      <li>
                        {' '}
                        The right to request Greentech Apps Foundation to
                        correct any errors in the personal information that we
                        hold about you. We may request some evidence to confirm
                        that an error has been made.
                      </li>
                      <li>
                        {' '}
                        The right to ask Greentech Apps Foundation to erase any
                        personal data that Greentech Apps Foundation holds on
                        you (although that right does not apply where Greentech
                        Apps Foundation has a legal obligation to retain it).
                      </li>
                      <li>
                        {' '}
                        The right to ask Greentech Apps Foundation to restrict
                        (suspend) processing of your personal data.
                      </li>
                      <li>
                        {' '}
                        The right to object to Greentech Apps Foundation
                        processing of your
                      </li>
                      personal data under certain circumstances.
                      <li>
                        {' '}
                        The right to ask Greentech Apps Foundation to provide a
                        copy of the personal data that were obtained from you in
                        a portable machine-readable format and to ask us to send
                        that copy to another data controller.
                      </li>
                      <li>
                        {' '}
                        Where you have given your consent, you have the right to
                        withdraw that consent at any time. If you do this, the
                        withdrawal of consent does not affect Greentech Apps
                        Foundation’s legal obligation to retain certain
                        information such as for the purpose of keeping taxation
                        records and it does not act retrospectively.
                      </li>
                      <li>
                        {' '}
                        The right to complain to a supervisory authority
                        (regulator). Greentech Apps Foundation invites you to
                        make any complaint directly to the Data Processing
                        Manager in the first instance but you have the right to
                        complain to the regulator at any time. In the United
                        Kingdom of Great Britain and Northern Ireland the
                        supervisory authority (regulator) is the Information
                        Commissioner and the further details can be found here:{' '}
                        <a href="https://ico.org.uk/for-the-public/">
                          https://ico.org.uk/for-the-public/
                        </a>
                      </li>
                    </ul>
                  </p>
                  <h3>Automated Decision Making</h3>
                  <p>
                    The following are the automated decisions or profiling that
                    is carried out using your personal data, with an explanation
                    to the reason and the logic involved, as well as the
                    consequences of processing your personal data in this way:
                    Greentech Apps Foundation may use MailChimp, if you have
                    signed up to receive updates or fundraising emails. After
                    signing up, mailchimp will send you an automated email
                    confirming your signup to an Greentech Apps Foundation list.
                    If you have donated to ‌Greentech Apps Foundation you will
                    receive an automated receipt for your donation from
                    DonorBox. Greentech Apps Foundation may use Google Firebase
                    to sync your bookmarks and notes from our apps. Greentech
                    Apps Foundation may use Google Play Services to provide
                    device specific information when you are using our
                    applications through Google Play. Your email may be used by
                    Greentech Apps Foundation to find similar people on facebook
                    to seek compliant ways to elicit their support. Facebook
                    uses your email to find your profile and then automatically
                    looks for similar profiles. The above services that are
                    involved in automating your data are GDPR compliant.
                  </p>
                  <h3>Further Processing</h3>
                  <p>
                    Greentech Apps Foundation does not and does not intend to
                    process your personal information for any purposes other
                    than those described in this notice and for which you have
                    given your consent or where Greentech Apps Foundation has a
                    legal obligation to do so.
                  </p>
                  <h3>Summary</h3>
                  <p>
                    Greentech Apps Foundation hopes that this notice explains
                    how we will use the personal information that you have
                    supplied to us and assures you that we are treating you
                    fairly and lawfully. If you have any concerns or complaints,
                    please do not hesitate to contact Greentech Apps Foundation
                    via the details above. Nothing in this documents seeks to or
                    should be taken to override any statutory rights that apply
                    to you.
                    <p className="mt-8 mb-4 text-neutral-5">
                      <small>Last updated November 2020</small>
                    </p>
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  </CommonContext.Provider>
);

PrivacyPolicy.propTypes = {
  location: PropTypes.object,
};

export default PrivacyPolicy;
